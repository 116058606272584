import TableToolbar from '@/components/TableToolbar.vue'
import RequestTable from '@/components/RequestTable.vue'

export default {
  name: 'List',
  components: { RequestTable, TableToolbar },
  data() {
    return {}
  }
}
